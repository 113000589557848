<template>
  <li>
    <router-link
      v-on:click.native="onClick"
      :class="[tabStatus]"
      :to="path"
    >
      {{ title }}
    </router-link>
  </li>
</template>

<script>
  export default {
    props: {
      activetab: {
        Number,
        required: true
      },
      title: {
        String,
        required: true
      },
      path: {
        String,
        required: true
      },
      tab: {
        Number,
        requried: true
      }
    },
    computed: {
     tabStatus() {
       return this.activetab === this.tab ? 'active' : ''
     }
    },
    methods: {
      onClick() {
        this.$emit('clicked', this.tab)
      }
    }
  }
</script>
