<template>
  <div class="tokyo_tm_portfolio">
    <div class="tokyo_tm_title">
      <div>
        <div class="left">
          <h3>{{ selectedItemList.visibleTitle }} Logs, Journals and More</h3>
          <p>The following are journals, planners, and logs I have created for sale on Amazon. It's a creative outlet which generates some revenue.
             I use a subscription service to source graphics for the book cover designs. The service provides me with with commercial rights to use
             various graphics so I don't actually create the artwork for these but I do create the layout.</p>
          <br />
          <p>I would appreciate a review if you like the item as it helps it rank better and produces more sales.</p>
        </div>

        <!-- End Left -->
        <div class="portfolio_filter">
          <hr />
          <ul>
            <PortfolioMenuItem
              v-for="(itemList, itemListIndex) in itemLists"
              :key="itemListIndex"
              @clicked="updateActivetab"
              :activetab="activetab"
              :title="itemList.title"
              :tab="itemList.tab"
              :path="itemList.path"
             />
          </ul>
          <hr />
        </div>
        <!-- End .portfolio-filter -->
      </div>
    </div>
    <!-- End tokyo_tm_title -->


    <SocialShare
      :page-url="currentAbsoluteRoute"
    />

    <PortfolioImageList
      :images="selectedItemList.items"
      :imageType="'BookList'"
    />
  </div>
</template>

<script>
  let ROOT_PATH = 'https://www.toasterartist.com'
  import PortfolioImageList from '../components/PortfolioImageList'
  import PortfolioMenuItem from '../components/PortfolioMenuItem'
  import SocialShare from '../components/SocialShare'
  import { shuffle, concat } from 'lodash'

  export default {
    components: {
      PortfolioImageList,
      PortfolioMenuItem,
      SocialShare,
    },
    metaInfo() {
      return {
        title: 'Toaster Artist Books',
        meta: [
            // Twitter Card
            {name: 'twitter:card', content: 'summary_large_image'},
            {name: 'twitter:title', content: 'Toaster Artist Books'},
            // image must be an absolute path
            {name: 'twitter:image', content: this.metaImage},
            {name: 'twitter:description', content: 'Logs, Journals and more as found on Amazon'},
            {name: 'twitter:site', content: '@toaster_artist'},
            {name: 'twitter:creator', content: '@toaster_artist'},
        ]
      }
    },
    data() {
      return {
        metaImage: ROOT_PATH + require('../assets/img/logo/logo.png'),
        activetab: parseInt(this.$route.params.tab || "1"),
        index: null,
        currentAbsoluteRoute: "http://toasterartist.com" + this.$route.path,
      };
    },
    computed: {
      allItems() {
        let images = concat(this.moneyItems,
                            this.healthItems,
                            this.educationItems,
                            this.businessItems,
                            this.miscItems)

        return shuffle(images)
      },
      moneyItems() {
        let requiredContext = require.context('../assets/img/books/money/', false, /\.jpg$/)
        let images = this.importImages(requiredContext)

        return shuffle(images)
      },
      healthItems() {
        let requiredContext = require.context('../assets/img/books/health/', false, /\.jpg$/)
        let images = this.importImages(requiredContext)

        return shuffle(images)
      },
      educationItems() {
        let requiredContext = require.context('../assets/img/books/education/', false, /\.jpg$/)
        let images = this.importImages(requiredContext)

        return shuffle(images)
      },
      businessItems() {
        let requiredContext = require.context('../assets/img/books/business/', false, /\.jpg$/)
        let images = this.importImages(requiredContext)

        return shuffle(images)
      },
      miscItems() {
        let requiredContext = require.context('../assets/img/books/misc/', false, /\.jpg$/)
        let images = this.importImages(requiredContext)

        return shuffle(images)
      },
      artItems() {
        let requiredContext = require.context('../assets/img/books/art/', false, /\.jpg$/)
        let images = this.importImages(requiredContext)

        return shuffle(images)
      },
      itemLists() {
        return [
          { tab: 1, items: this.allItems, title: 'ALL', visibleTitle: 'All', path: { path: '/book-lists/1' } },
          { tab: 2, items: this.moneyItems, title: 'MNY', visibleTitle: 'Money', path: { path: '/book-lists/2' } },
          { tab: 3, items: this.healthItems, title: 'HLT', visibleTitle: 'Health', path: { path: '/book-lists/3' } },
          { tab: 4, items: this.educationItems, title: 'EDU', visibleTitle: 'Education', path: { path: '/book-lists/4' } },
          { tab: 5, items: this.businessItems, title: 'BIZ', visibleTitle: 'Business', path: { path: '/book-lists/5' } },
          { tab: 6, items: this.artItems, title: 'ART', visibleTitle: 'Art', path: { path: '/book-lists/6' } },
          { tab: 7, items: this.miscItems, title: 'MSC', visibleTitle: 'Miscellaneous', path: { path: '/book-lists/7' } },
        ]
      },
      selectedItemList() {
        return this.itemLists.find(list => list.tab === this.activetab)
      }
    },
    methods: {
      importImages(requiredContext) {
        let images = []

        requiredContext.keys().map(key => {
          images.push({ src: requiredContext(key), thumb: requiredContext(key) })
        })

        return images
      },
      updateActivetab(tab) {
        this.activetab = tab
      },
    },
    watch: {
      $route(to,) {
        this.activetab = parseInt(to.params.tab)
        this.currentAbsoluteRoute = ROOT_PATH + to.path
        this.metaImage = ROOT_PATH + this.selectedItemList.items[0].src
      }
    },
  };
</script>

<style lang="scss" scoped>
  .left {
    padding-bottom: 20px;
  }

  .portfolio_filter {
    padding-top: 1px;
  }
</style>
